import React, {useState, useEffect} from 'react';

function CercaForm(props){
  const [inputs, setInputs] = useState({});

  const meta = props.meta;
  const soluzioni = props.soluzioni;
  const ricerca = props.parametriRicerca;
  const infoSede = props.infoSede;
  const capienzaLabel = props.capienzaLabel;

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const news = Object.assign(inputs, {[name]: value});
    setInputs(news);

    if( name === 'sede_id' ){
      props.onInfoSedeChange( returnInfoSede(value) );
    }
    if( name === 'tipologia' ){
      props.onCapienzaLabelChange( returnCapienzaLabel(value) );
    }
    props.onErrorChange('');
  }

  function returnInfoSede(value){
    const sede_idx = meta.sedi.findIndex( element => parseInt(element.id) === parseInt(value));
    if( sede_idx === undefined || sede_idx === '' || sede_idx === -1) return '';
    const apertura = new Date(meta.sedi[sede_idx].orario_apertura);
    const chiusura = new Date(meta.sedi[sede_idx].orario_chiusura);
    const inizio_pranzo = new Date(meta.sedi[sede_idx].orario_inizio_pranzo);
    const fine_pranzo = new Date(meta.sedi[sede_idx].orario_fine_pranzo);

    let intervallo = '';
    if( inizio_pranzo.getTime() !== fine_pranzo.getTime() ){
      intervallo = 'alle ' + inizio_pranzo.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) + ' e dalle ' + fine_pranzo.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})
    }
    else{
      intervallo = '';
    }

    const infosede =  (<div className="info-sede-container">
      Orari apertura <span className="info-sede-nome">{meta.sedi[sede_idx].nome}</span>: dalle <span className="info-sede-apertura">{apertura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})}</span> <span className="info-sede-intervallo">{intervallo}</span> alle <span className="info-sede-chiusura">{chiusura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})}</span>
    </div>

    );
    return infosede;
  }

  function returnCapienzaLabel(value){
    const tipologia_idx = meta.tipologie.findIndex( element => parseInt(element.id) === parseInt(value));
    if( tipologia_idx === undefined || tipologia_idx === '' || tipologia_idx === -1) return '';
    const tipologia_slug = meta.tipologie[tipologia_idx].slug_bcok;
    let label = '';
    switch (tipologia_slug) {
      case 'ufficio':
        label = 'N° scrivanie: ';
        break;
      case 'sala-riunioni':
        label = "Per quante persone? ";
        break;
      case 'coworking':
        label = "N° postazioni lavoro: ";
        break;
      default:
        label = "Capienza";
    }

    return label;
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.onErrorChange('');
    const meta = props.meta;
    const auth_token = meta.auth_token;

    const num_sedi = Object.keys(meta.sedi).length;
    const num_tipo_carnet = Object.keys(meta.tipologie).length;
    if( num_sedi === 1 && inputs.sede_id === undefined){
      const sede = Object.assign(inputs, {sede_id: meta.sedi[0].id});
      setInputs(sede);
    }

    if( inputs.sede_id === '' || inputs.sede_id === undefined ){
      props.onErrorChange('Attenzione: devi selezionare la sede dove prenotare' );
      return false;
    }

    if( num_tipo_carnet === 1 && inputs.tipologia === undefined){
      const crnt = Object.assign(inputs, {tipologia: meta.tipologie[0].id});
      setInputs(crnt);
    }

    if( inputs.tipologia === '' || inputs.tipologia=== undefined ){
      props.onErrorChange('Attenzione: devi selezionare il tipo di spazio che vuoi prenotare' );
      return false;
    }


    const sede_obj = meta.sedi.find( element => parseInt(element.id) === parseInt(inputs.sede_id));
    const scadenzaCarnet = new Date(meta.carnet.data_scadenza);
    const dataInizio = new Date(inputs.data + 'T' + inputs.ora_inizio + ':00');
    const dataFine = new Date( inputs.data + 'T' + inputs.ora_fine + ':00');

    // check: step di 30 minuti
    if( dataInizio.toLocaleTimeString(undefined, {minute: 'numeric'}) !== "0" && dataInizio.toLocaleTimeString(undefined, {minute: 'numeric'}) !== "30" ){
      props.onErrorChange('Attenzione: sono consentite solo prenotazioni che iniziano all\'ora o alla mezzora. La tua prenotazione inizia alle ' + dataInizio.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) );
      return false;
    }
    if( dataFine.toLocaleTimeString(undefined, {minute: 'numeric'}) !== "0" && dataFine.toLocaleTimeString(undefined, {minute: 'numeric'}) !== "30" ){
      props.onErrorChange('Attenzione: sono consentite solo prenotazioni che terminano all\'ora o alla mezzora. La tua prenotazione termina alle ' + dataFine.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) );
      return false;
    }

    // feedback contatti
    const contattaci = sede_obj.nome + ', email: ' + sede_obj.email + ', tel: ' + sede_obj.telefono + '.';

    // Controlliamo che la data non sia oltre la scadenza Carnet
    if( dataInizio > scadenzaCarnet){
      props.onErrorChange('La prenotazione non può essere in una data successiva alla scadenza del carnet, che sarà il ' + scadenzaCarnet.toLocaleDateString(undefined, {
        day:   'numeric',
        month: 'numeric',
        year:  'numeric',
          }) );
          return false;
    };

    // check: data e ora non passate
    if( dataInizio < new Date() ){
      props.onErrorChange('Attenzione: la data o l\'ora che vuoi prenotare è già passata');
      return false;
    }

    // non facciamo prenotare di sabato e domenica
    if( dataInizio.getUTCDay() === 0 || dataInizio.getUTCDay() === 6 ){
      props.onErrorChange('Attenzione: da questa applicazione al momento non puoi prenotare al sabato o alla domenica. Contattaci: ' + contattaci );
      return false;
    }

    //check ora inizio uguale ora fine
    if( dataFine.getTime() === dataInizio.getTime() ){
      props.onErrorChange('Attenzione:  l\'orario di inizio e fine coincidono');
      return false;
    };

    //check ora inizio prima di ora fine
    if( dataFine <= dataInizio ){
      props.onErrorChange('Attenzione: forse hai scambiato l\'orario di inizio e fine prenotazione?');
      return false;
    };

    // check: orari compresi in orari sede
    const apertura = new Date(sede_obj.orario_apertura);
    const chiusura = new Date(sede_obj.orario_chiusura);

    if( dataInizio.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) < apertura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) ){
      props.onErrorChange('Attenzione: la tua prenotazione non può iniziare prima dell\'orario di apertura della sede. La sede apre alle ' + apertura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}));
      return false;
    }

    if( dataFine.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) > chiusura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'})  ){
      props.onErrorChange('Attenzione: la tua prenotazione non può terminare dopo l\'orario di chiusura della sede. La sede chiude alle ' + chiusura.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}));
      return false;
    }

    // check capienza almeno 1
    if( inputs.capienza < 1 ){
      props.onErrorChange('Attenzione: la capienza deve essere maggiore di 0');
      return false;
    }
    // setLoading( true );
    props.onLoadingChange(true);

    props.onParametriRicercaChange(inputs);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'auth_token': auth_token,
            'sede_id': inputs.sede_id,
            // 'sede_id': sede_id,
            'provincia': inputs.provincia,
            'data': inputs.data,
            'ora_inizio': inputs.ora_inizio,
            'ora_fine': inputs.ora_fine,
            'capienza': inputs.capienza,
            'tipologia': inputs.tipologia,
          })
    };
    fetch( '/wp-json/spaceinvaders-carnet/v1/cerca/', requestOptions )
      .then(async response => {
          const soluzioni = await response.json();
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (soluzioni && soluzioni.message) || response.statusText;
              return Promise.reject(error);
          }
          else{
            props.onLoadingChange(false);

            // setLoading( false );

            if( Object.keys(soluzioni.data).length < 1 ){
              props.onErrorChange('Non ho trovato disponibilità. Prova a cambiare la tua ricerca oppure contattaci: ' + contattaci);
            }
            else{
              setInputs('');
              props.onSoluzioniChange(soluzioni.data);
            }
          }
      })
      .catch(error => {
          // setLoading( false );
          props.onLoadingChange(false);

          props.onErrorChange(error.toString());
      });
      // setInputs({});
  }


  useEffect(() => {
    if(!meta )return null;
    const num_sedi = Object.keys(meta.sedi).length;
    if( num_sedi <=1 ){
      if (infoSede === ''){
        props.onInfoSedeChange( returnInfoSede(meta.sedi[0].id) );
      }
    }
   });

  useEffect(() => {
    if(!meta )return null;
    const num_tipo_carnet = Object.keys(meta.tipologie).length;

    if (capienzaLabel === ''){
      if( num_tipo_carnet <=1 ){
        props.onCapienzaLabelChange( returnCapienzaLabel(meta.tipologie[0].id) );
      }
      else{
        props.onCapienzaLabelChange( 'Capienza: ' );
      }
    }
   });


  if( (meta === undefined || meta === '')
      || (soluzioni !== undefined && soluzioni !== '')
    ){
      return null;
  }

  const num_tipo_carnet = Object.keys(meta.tipologie).length;
  let input_tipo_carnet;
  if( num_tipo_carnet > 1 ){
    input_tipo_carnet = (
      <div className="cerca-form-tipo-carnet">
        <label className="cerca-tipo-carnet" htmlFor="tipologia">
          Cosa ti serve? <span className="campo-obbligatorio">*</span>
        </label>
        <select className="cerca-tipo-carnet" name="tipologia" id="tipologia" onChange={handleInputChange} value={ricerca.tipologia} >
          <option key="-1" value="">Scegli</option>
          {meta.tipologie.map(tipo =>
            <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>
          )}
        </select>
      </div>
    );
  }
  else{
    input_tipo_carnet = (
      <input className="input-tipo-carnet" name="tipologia" type="hidden" value={meta.tipologie[0].id}  onChange={handleInputChange} />
    );
  }

  const num_sedi = Object.keys(meta.sedi).length;
  let input_sedi;
  if( num_sedi > 1 ){
    input_sedi = (
      <div className="cerca-form-cerca-sedi">
        <label className="cerca-sedi" htmlFor="sede_id">Presso <span className="campo-obbligatorio">*</span></label>
        <select className="cerca-sedi" name="sede_id" id="sede_id" onChange={handleInputChange} value={ricerca.sede_id} >
          <option key="-1" value="">Scegli la sede...</option>
          {meta.sedi.map(sede =>
            <option key={sede.id} value={sede.id}>{sede.nome}</option>
          )}
        </select>
      </div>
    );
  }
  else{
    input_sedi = (
      <input className="cerca-sedi" name="sede_id" type="hidden" value={meta.sedi[0].id}  onChange={handleInputChange} />
    );
  }

  return (
    <div className="form-container cerca-form-container">
      <h4 className="cerca-form-titolo">Quando vuoi prenotare?</h4>
      <form onSubmit={handleSubmit}>
        <datalist id="orari">
            <option value="08:00">08:00</option>
            <option value="08:30">08:30</option>
            <option value="09:00">09:00</option>
            <option value="09:30">09:30</option>
            <option value="10:00">10:00</option>
            <option value="10:30">10:30</option>
            <option value="11:00">11:00</option>
            <option value="11:30">11:30</option>
            <option value="12:00">12:00</option>
            <option value="12:30">12:30</option>
            <option value="13:00">13:00</option>
            <option value="13:30">13:30</option>
            <option value="14:00">14:00</option>
            <option value="14:30">14:30</option>
            <option value="15:00">15:00</option>
            <option value="15:30">15:30</option>
            <option value="16:00">16:00</option>
            <option value="16:30">16:30</option>
            <option value="17:00">17:00</option>
            <option value="17:30">17:30</option>
            <option value="18:00">18:00</option>
            <option value="18:30">18:30</option>
            <option value="19:00">19:00</option>
            <option value="19:30">19:30</option>
        </datalist>
        {input_sedi}
        {infoSede}
        {input_tipo_carnet}
        <div className="cerca-form-cerca-data">
          <label className="cerca-data" htmlFor="data">Data <span className="campo-obbligatorio">*</span></label>
          <input className="cerca-data" name="data" id="data" type="date" onChange={handleInputChange} required />
        </div>
        <div className="cerca-form-cerca-ora-inizio">
          <label className="cerca-ora-inizio" htmlFor="ora_inizio">Dalle <span className="campo-obbligatorio">*</span></label>
          <input className="cerca-ora-inizio" list="orari" name="ora_inizio" id="ora_inizio" type="time" step="1800" onChange={handleInputChange}required />
        </div>
        <div className="cerca-form-cerca-ora-fine">
          <label className="cerca-ora-fine" htmlFor="ora_fine">Alle <span className="campo-obbligatorio">*</span></label>
          <input className="cerca-ora-fine" list="orari" name="ora_fine" id="ora_fine" type="time" step="1800" onChange={handleInputChange} required />
        </div>
        <div className="cerca-form-cerca-capienza">
          <label className="cerca-capienza" htmlFor="capienza">{capienzaLabel} <span className="campo-obbligatorio">*</span></label>
          <input className="cerca-capienza" maxLength="3" size="3" max="999" name="capienza" id="capienza" type="number" onChange={handleInputChange}  min="1" required />
        </div>
        <button className="cerca-submit" type="submit">Cerca disponibilità</button>
      </form>
    </div>
  );

}

export default CercaForm;
