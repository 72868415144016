import React from 'react';
import { InfoPrenotazione } from './App';

function ConfermaForm(props) {
  const meta = props.meta;
  const soluzioni = props.soluzioni;
  const ricerca = props.parametriRicerca;
  const scelta = props.scelta;
  const addebito = props.addebito;
  const conferma = props.conferma;

  function handleConferma(event){
    event.preventDefault();
    callConferma('conferma');
  }

  function handleNega(event){
    event.preventDefault();
    callConferma('nega');
  }


  function callConferma( action ){
    props.onErrorChange('');

    props.onLoadingChange(true);

    // setLoading( true );
    const auth_token = meta.auth_token;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Basic ' + btoa("spaceinvaders:test"),
        },
        body: JSON.stringify({
            auth_token: auth_token,
            action: action,
            codice_conferma: addebito.codice_conferma,
            codice_carnet: meta.idCarnet,
            email:scelta.sceltaEmail,
            nome_referente:scelta.sceltaNome,
            cognome_referente:scelta.sceltaCognome,
            telefono:scelta.sceltaTelefono,
            p_iva:meta.cliente.p_iva,
            data:ricerca.data,
            ora_fine:ricerca.ora_fine,
            ora_inizio:ricerca.ora_inizio,
            risorsa_id:soluzioni[scelta.sceltaSoluzione].id,
            sede_id: meta.sedi.find( element => element.id === soluzioni[scelta.sceltaSoluzione].sede_id).bcok_id,
          })
    };

    fetch( '/wp-json/spaceinvaders-carnet/v1/conferma/', requestOptions )
      .then(async response => {
          const conferma = await response.json();
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (conferma && conferma.message) || response.statusText;
              return Promise.reject(error);
          }
          else{

            // setLoading( false );
            props.onLoadingChange(false);

            conferma.action = action;
            props.onConfermaChange(conferma);
          }
      })
      .catch(error => {
          // setLoading( false );
          props.onLoadingChange(false);

          props.onErrorChange(error.toString());
      });

  }

  if(  (meta === undefined || meta === '')
        || (soluzioni === undefined || soluzioni === '')
        || ( addebito === undefined || addebito === '')
        || ( conferma !== undefined && conferma !== '')
    ){
      return null;
  }

  return (
    <div className="form-container conferma-form-container">

      <h4 className="conferma-form-titolo">Confermi la prenotazione?</h4>
      <form onSubmit={handleConferma}>
        <InfoPrenotazione meta={meta} addebito={addebito} />

        <div className="conferma-addebito-proposto">
          dal tuo carnet <i className="conferma-addebito-nome-carnet">{meta.carnet.nome_carnet}</i> (cod. <span className="conferma-addebito-cod-carnet">{meta.idCarnet}</span>) verranno scalate <b className="conferma-addebito-descrizione">{addebito.addebito_proposto}</b>
        </div>
        <button className="conferma-annulla" onClick={handleNega}>Annulla</button>
        <button className="conferma-submit" type="submit">Confermo la prenotazione!</button>
      </form>
    </div>
  );
}

export default ConfermaForm;
