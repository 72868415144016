import React, {useState} from 'react';
// import { Loading } from './App';

// console.log(sbc_config);
function LoginForm(props){


  const [idCarnet, setIdCarnet] = useState('');
  const [idCliente, setIdCliente] = useState('');
  // const [loading, setLoading] = useState(false);

  function handleInputChange(e){
    switch (e.target.name) {
      case 'idCarnet':
        setIdCarnet(e.target.value);
        break;
      case 'idCliente':
        setIdCliente(e.target.value);
        break;
      default:
    }
    props.onErrorChange('');
  }


  function handleSubmit(event) {
    event.preventDefault();
    if( idCarnet === '' || idCliente === '' ){
        props.onErrorChange( 'N° Carnet e P.Iva sono obbligatori' );
    }
    else{
      props.onErrorChange('');
      props.onLoadingChange(true);
      // setLoading( true );
      // fetch(sbc_config.restBase + 'spaceinvaders-carnet/v1/connect/' + idCarnet +'?cliente_id=' + idCliente )
      fetch( '/wp-json/spaceinvaders-carnet/v1/connect/' + idCarnet +'?cliente_id=' + idCliente )
        .then(async response => {
            const meta_data = await response.json();
            if (!response.ok) {
                // get error message from body or default to response statusText
                const error = (meta_data && meta_data.message) || response.statusText;
                return Promise.reject(error);
            }
            else{
              // combiniamo le sedi per avere a disposizione solo quelle che realmente l'utente può utilizzare
              const sedi_space = meta_data.booking.sedi;
              const sedi_carnet = meta_data.carnet.utilizzabile_presso;
              const sedi = sedi_space.filter( sede_space =>{
                return sedi_carnet.find(sede_carnet => parseInt(sede_carnet.sede_id) === parseInt(sede_space.bcok_id)) !== undefined;
              })
              if( Object.keys(sedi).length < 1 ){
                const error = 'Spiacente, il sito non è abilitato ad accettare i Carnet di questa sede';
                return Promise.reject(error);
              }

              // incrociamo le tipologie di Spaceinvaders con i tipi_carnet di bcok
              // per avere a disposizione solo quelli che il cliente può effettivamente utilizzare
              const tipologie_space = meta_data.booking.tipologia;
              const tipi_carnet = meta_data.carnet.tipo_carnet;

              const tipologie = tipologie_space.filter( tipologia_space =>{
                return tipi_carnet.find(tipo_carnet => tipo_carnet.slug === tipologia_space.slug_bcok) !== undefined;
              })

              if( Object.keys(tipologie).length < 1 ){
                const error = 'Spiacente, il sito non è abilitato ad accettare i Carnet di questa tipologia';
                return Promise.reject(error);
              }
              meta_data.tipologie = tipologie;
              meta_data.sedi = sedi;
              meta_data.idCarnet = idCarnet;
              meta_data.idCliente = idCliente;
              props.onMetaChange(meta_data);
              // setLoading(false);
              props.onLoadingChange(false);
            }
        })
        .catch(error => {
          props.onLoadingChange(false);

          // setLoading(false);
          props.onErrorChange( error.toString() );
        });
    }
  }

  if( props.meta !== undefined && props.meta !== ''){
    return null;
  }
  return (
    <div className="form-container login-form-container">
      <form onSubmit={handleSubmit}>
      <div className="login-form-cod-carnet">
        <label htmlFor="idCarnet" className="login-cod-carnet">Codice carnet <span className="campo-obbligatorio">*</span></label>
        <input className="login-cod-carnet" type="text" name="idCarnet" id="idCarnet" value={idCarnet} onChange={handleInputChange} placeholder="codice carnet..." />
      </div>
      <div className="login-form-id_cliente">
        <label htmlFor="idCliente" className="login-id_cliente">P. IVA <span className="campo-obbligatorio">*</span> </label>
        <input className="login-id_cliente" type="text" name="idCliente" id="idCliente" value={idCliente} onChange={handleInputChange} placeholder="la tua p. iva..." />
      </div>
      <small className="help-text">Inserisci i dati del carnet che vuoi usare e la tua p.iva per procedere alla prenotazione...</small>
      <br />
      <button className="login-submit" type="submit">Continua</button>

      </form>
    </div>
  );
}

export default LoginForm;
