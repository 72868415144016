import React from 'react';
import { InfoPrenotazione } from './App';

function Finale(props) {
  // const [loading, setLoading] = useState(false);

  const meta = props.meta;
  const soluzioni = props.soluzioni;
  // const ricerca = props.parametriRicerca;
  // const scelta = props.scelta;
  const addebito = props.addebito;
  const conferma = props.conferma;

  function handleClick(event) {
    event.preventDefault();
    props.onMetaChange('');
    props.onSoluzioniChange('');
    props.onParametriRicercaChange('');
    props.onAddebitoChange('');
    props.onSceltaChange('');
    props.onConfermaChange('');
    props.onErrorChange('');
    props.onInfoSedeChange('');
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.onSoluzioniChange('');
    props.onParametriRicercaChange('');
    props.onAddebitoChange('');
    props.onSceltaChange('');
    props.onConfermaChange('');
    props.onErrorChange('');
    props.onInfoSedeChange('');
  }

  if(  (meta === undefined || meta === '')
        || (soluzioni === undefined || soluzioni === '')
        || ( addebito === undefined || addebito === '')
        || ( conferma === undefined || conferma === '')
    ){
      return null;
    }

  if( conferma.action === 'conferma'){
    return (
      <div className="feedback-finale-container feedback-finale-container-conferma">
        <h4 className="feedback-finale-form-titolo">Prenotazione Confermata</h4>
        <InfoPrenotazione meta={meta} addebito={addebito} />
        <form  onSubmit={handleSubmit}>
          <button className="feedback-finale-submit" type="submit">Nuova prenotazione</button>
          <button className="feedback-finale-fine" type="button" onClick={handleClick}>Fine</button>
        </form>

      </div>
    );
  }
  else if( conferma.action === 'nega'){
    return (
    <div className="feedback-finale-container feedback-finale-container-nega">

      <h4 className="feedback-finale-form-titolo">Prenotazione cancellata</h4>
      <span className="feedback-finale-message">Nessun addebito sul tuo carnet</span>
      <form  onSubmit={handleSubmit}>
        <button className="feedback-finale-submit" type="submit">Nuova prenotazione</button>
        <button className="feedback-finale-fine" type="button" onClick={handleClick}>Fine</button>
      </form>

    </div>
  );
  }

}

export default Finale;
