import React, {useState} from 'react';

function ScegliForm(props){
  const [inputs, setInputs] = useState({});

  const meta = props.meta;
  const soluzioni = props.soluzioni;
  const ricerca = props.parametriRicerca;
  const addebito = props.addebito;
  // const scelta = props.scelta;


  function handleCambiaRicerca(event){
    props.onParametriRicercaChange('');
    props.onSoluzioniChange('');
    props.onInfoSedeChange('');
    props.onSceltaChange('');
    setInputs('');
    props.onLoadingChange(false);
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const news = Object.assign(inputs, {[name]: value});
    setInputs(news);


    props.onErrorChange('');
  }

  function handleSubmit(event) {
    event.preventDefault();
    const auth_token = meta.auth_token;
    props.onErrorChange('');

    if( inputs.sceltaSoluzione === '' || inputs.sceltaSoluzione === undefined ){
      props.onErrorChange('Attenzione: devi selezionare una delle soluzioni proposte' );
      return false;
    }

    if( inputs.sceltaNome === '' || inputs.sceltaNome === undefined ){
      props.onErrorChange('Attenzione: inserisci il tuo nome o quello della persona referente per questa prenotazione' );
      return false;
    }

    if( inputs.sceltaCognome === '' || inputs.sceltaCognome === undefined ){
      props.onErrorChange('Attenzione: inserisci il tuo cognome o quello della persona referente per questa prenotazione' );
      return false;
    }

    if( inputs.sceltaEmail === '' || inputs.sceltaEmail === undefined ){
      props.onErrorChange('Attenzione: inserisci la tua email o quella della persona referente per questa prenotazione' );
      return false;
    }


    props.onLoadingChange(true);

    props.onSceltaChange(inputs);

    console.log('Scegli inputs', inputs);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'auth_token': auth_token,
            p_iva:meta.cliente.p_iva,
            codice_carnet: meta.idCarnet,
            persona_email:inputs.sceltaEmail,
            persona_nome:inputs.sceltaNome,
            persona_cognome:inputs.sceltaCognome,
            persona_telefono:inputs.sceltaTelefono,
            prenotazione_capienza: ricerca.capienza,
            prenotazione_fine:ricerca.data + 'T' + ricerca.ora_fine + ':00Z',
            prenotazione_inizio:ricerca.data + 'T' + ricerca.ora_inizio + ':00Z',
            prenotazione_nome_risorsa:soluzioni[inputs.sceltaSoluzione].nome,
            prenotazione_prezzo: soluzioni[inputs.sceltaSoluzione].prezzi_cliente.find( element => element.id === 999 ).prezzo, // restituiamo sempre il prezzo calcolato, cioè il prezzo reale
            prenotazione_tipo_listino: 999,
            prenotazione_tipo_spazio: soluzioni[inputs.sceltaSoluzione].tipologia_id,
            risorsa_id:soluzioni[inputs.sceltaSoluzione].id,
            sede_id: meta.sedi.find( element => element.id === soluzioni[inputs.sceltaSoluzione].sede_id).bcok_id,
          })
    };
    fetch( '/wp-json/spaceinvaders-carnet/v1/check/', requestOptions )
      .then(async response => {
          const check = await response.json();
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (check && check.message) || response.statusText;
              return Promise.reject(error);
          }
          else{
                        setInputs( '');
            props.onLoadingChange(false);

            props.onAddebitoChange(check.data);
          }
      })
      .catch(error => {
          // setLoading( false );
          props.onLoadingChange(false);

          props.onErrorChange(error.toString());
      });
  }


  if(  (meta === undefined || meta === '')
        || (soluzioni === undefined || soluzioni === '')
        || ( addebito !== undefined && addebito !== '')
    ){
      return null;
  }

  const radioSoluzioni = (
    <ul className="scegli-elenco-soluzioni">
      {soluzioni.map((tipo, idx) =>
        <li key={idx} className="scegli-soluzione">
            <input type="radio" className="scegli-scelta-soluzione" name="sceltaSoluzione" id={tipo.id} key={tipo.id} value={idx} onChange={handleInputChange}  />
            <label htmlFor={tipo.id} className="scegli-nome-spazio">{tipo.nome}</label>
            <i className="scegli-descrizione-spazio">{tipo.descrizione}</i>
        </li>
      )}
    </ul>
  );
  const data_riepilogo = new Date(ricerca.data);
      return (
    <div className="form-container scegli-form-container">
      <form  onSubmit={handleSubmit}>
        <h4 className="scegli-form-titolo">Scegli la soluzione che preferisci...</h4>
        <div className="scegli-form-riepilogo-ricerca">
        c/o <span className="scegli-form-riepilogo-ricerca-sede">{meta.sedi.find( element => parseInt(element.id) === parseInt(ricerca.sede_id)).nome}</span> il <span className="scegli-form-riepilogo-ricerca-data">{data_riepilogo.toLocaleDateString(undefined, {day:   'numeric',month: 'numeric',year:  'numeric',}) }</span> dalle: <span className="scegli-form-riepilogo-ricerca-ora-inizio">{ricerca.ora_inizio}</span> alle: <span className="scegli-form-riepilogo-ricerca-ora-fine">{ricerca.ora_fine}</span>

        </div>
        <div className="scegli-cambia-ricerca">
          <button className="scegli-cambia-ricerca" onClick={handleCambiaRicerca}>oppure cambia la ricerca</button>
        </div>
        {radioSoluzioni}
        <div className="scegli-blocco-referente-prenotazione">
          <h4 className="scegli-form-titolo-referente">Referente della prenotazione</h4>
          <div className="scegli-form-referente-nome">
            <label htmlFor="sceltaNome" className="scegli-nome">Nome <span className="campo-obbligatorio">*</span></label>
            <input className="scegli-nome" placeholder="Nome" type="text" name="sceltaNome" id="sceltaNome" onChange={handleInputChange}  />
          </div>
          <div className="scegli-form-referente-cognome">
            <label htmlFor="sceltaCognome" className="scegli-cognome">Cognome <span className="campo-obbligatorio">*</span></label>
            <input className="scegli-cognome" placeholder="Cognome" type="text" name="sceltaCognome" id="sceltaCognome" onChange={handleInputChange}  />
          </div>
          <div className="scegli-form-referente-email">
            <label htmlFor="sceltaEmail" className="scegli-email">Email <span className="campo-obbligatorio">*</span></label>
            <input className="scegli-email" placeholder="Email" type="email" name="sceltaEmail" id="sceltaEmail" onChange={handleInputChange}  />
          </div>
          <div className="scegli-form-referente-telefono">
            <label htmlFor="sceltaEmail" className="scegli-telefono">Telefono: </label>
            <input className="scegli-telefono" placeholder="Telefono" type="text" name="sceltaTelefono" id="sceltaTelefono" onChange={handleInputChange} />
          </div>
        </div>

        <button className="scegli-submit" type="submit">Ok, prenota</button>
      </form>
    </div>
  );
}

export default ScegliForm;
