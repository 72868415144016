import './App.css';
import React, {useState} from 'react';
import LoginForm from './LoginForm';
import CercaForm from './CercaForm';
import ScegliForm from './ScegliForm';
import ConfermaForm from './ConfermaForm';
import Finale from './Finale';

// export const sbc_config = window.sbc_config; //uncommenta se dovrai usare le variabili gloabali di configurazioni


// meta id per test: 3522647 - 3522290 - 	3523431 - scaduto: 	3522290 - validità UA: 	3520491	(piva: IT 03910351208 )
// p.iva per test: 03557480369
          //       03910351208
function App(props) {

  const [meta, setMeta] = useState('');
  const [parametriRicerca, setParametriRicerca] = useState('');
  const [soluzioni, setSoluzioni] = useState('');
  const [addebito, setAddebito] = useState('');
  const [scelta, setScelta] = useState('');
  const [conferma, setConferma] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [infoSede, setInfoSede] = useState('');
  const [capienzaLabel, setCapienzaLabel] = useState('');

  console.log('meta', meta);
  console.log('parametriRicerca', parametriRicerca);
  console.log('soluzioni', soluzioni);
  console.log('scelta', scelta);
  console.log('addebito', addebito);
  console.log('conferma', conferma);
  console.log('error', error);
  console.log('loading', loading);
  console.log('infoSede', infoSede);
  console.log('capienzaLabel', capienzaLabel);

  return (
    <div className="App">

      <CarnetInUso onCapienzaLabelChange={setCapienzaLabel} onErrorChange={setError} onInfoSedeChange={setInfoSede} infoSede={infoSede}  onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} onParametriRicercaChange={setParametriRicerca} onAddebitoChange={setAddebito} onSceltaChange={setScelta} onConfermaChange={setConferma}/>

      <LoginForm onCapienzaLabelChange={setCapienzaLabel} onLoadingChange={setLoading} onErrorChange={setError} onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} soluzioni={soluzioni}  onParametriRicercaChange={setParametriRicerca} parametriRicerca={parametriRicerca} onAddebitoChange={setAddebito} addebito={addebito} onSceltaChange={setScelta} scelta={scelta} onConfermaChange={setConferma} conferma={conferma}  />

      <CercaForm onCapienzaLabelChange={setCapienzaLabel} capienzaLabel={capienzaLabel} onLoadingChange={setLoading} onErrorChange={setError} onInfoSedeChange={setInfoSede} infoSede={infoSede} onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} soluzioni={soluzioni}  onParametriRicercaChange={setParametriRicerca} parametriRicerca={parametriRicerca} onAddebitoChange={setAddebito} addebito={addebito} onSceltaChange={setScelta} scelta={scelta} onConfermaChange={setConferma} conferma={conferma} />
        <ScegliForm onCapienzaLabelChange={setCapienzaLabel} onLoadingChange={setLoading} onErrorChange={setError} onInfoSedeChange={setInfoSede} infoSede={infoSede} onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} soluzioni={soluzioni}  onParametriRicercaChange={setParametriRicerca} parametriRicerca={parametriRicerca} onAddebitoChange={setAddebito} addebito={addebito} onSceltaChange={setScelta} scelta={scelta} onConfermaChange={setConferma} conferma={conferma} />
          <ConfermaForm onCapienzaLabelChange={setCapienzaLabel} onLoadingChange={setLoading} onErrorChange={setError} onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} soluzioni={soluzioni}  onParametriRicercaChange={setParametriRicerca} parametriRicerca={parametriRicerca} onAddebitoChange={setAddebito} addebito={addebito} onSceltaChange={setScelta} scelta={scelta} onConfermaChange={setConferma} conferma={conferma} />
          <Finale onCapienzaLabelChange={setCapienzaLabel} onErrorChange={setError} onInfoSedeChange={setInfoSede} infoSede={infoSede} onMetaChange={setMeta} meta={meta} onSoluzioniChange={setSoluzioni} soluzioni={soluzioni}  onParametriRicercaChange={setParametriRicerca} parametriRicerca={parametriRicerca} onAddebitoChange={setAddebito} addebito={addebito} onSceltaChange={setScelta} scelta={scelta} onConfermaChange={setConferma} conferma={conferma}  />
          <ErrorMessage error={error} />
          <Loading loading={loading} />
    </div>
  );
};
export default App;

function CarnetInUso(props) {
    const meta = props.meta;

    function handleClick(event) {
        props.onMetaChange('');
        props.onSoluzioniChange('');
        props.onParametriRicercaChange('');
        props.onAddebitoChange('');
        props.onSceltaChange('');
        props.onConfermaChange('');
        props.onErrorChange('');
        props.onInfoSedeChange('');
        props.onCapienzaLabelChange('');
    }

    if( meta === undefined || meta === '' ){
        return null;
    }
    return (
      <div className="info-carnet-container">
        <span className="info-carnet">
          Stai usando <i className="nome-carnet">{meta.carnet.nome_carnet}</i>
        </span>
        <br />
        <span className="info-carnet-cambia">
          <button className="info-carnet-cambia" onClick={handleClick} >Cambia Carnet</button>
        </span>
        <hr />
      </div>
    );
}

export function InfoPrenotazione(props){
  const sedi = props.meta.sedi;
  const prenotazione = props.addebito.prenotazione;

  const dataInizio = new Date(prenotazione.prenotazione_inizio);
  const dataFine = new Date(prenotazione.prenotazione_fine);
  return(
      <ul className="info-prenotazione-container">
        <li className="info-prenotazione-presso">
          Presso: <span className="info-prenotazione-nome-sede">{sedi.find( element => parseInt(element.bcok_id) === parseInt(prenotazione.sede_id)).nome}</span>
        </li>

        <li className="info-prenotazione-nome-risorsa">
          <i className="info-prenotazione-nome-risorsa-voce">{prenotazione.prenotazione_nome_risorsa}</i>
        </li>
        <li className="info-prenotazione-data">
          Data: <span className="info-prenotazione-quando">{dataInizio.toLocaleDateString(undefined, {
            day:   'numeric',
            month: 'numeric',
            year:  'numeric',
          }) } </span>
        </li>

        <li className="info-prenotazione-ora-inizio">
          Dalle: <span className="info-prenotazione-quando-inizio">{dataInizio.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric', timeZone: 'UTC'})}</span>
        </li>

        <li className="info-prenotazione-ora-fine">
          Alle: <span className="info-prenotazione-quando-fine">{dataFine.toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric', timeZone: 'UTC'})}</span>
        </li>
      </ul>
  );
}

export function ErrorMessage(props){
    const message = props.error;
    if( message === '' || message === undefined   ){
        return null;
    }
    return(
        <div className="error-message-container">
          <span className="error-message">{message}</span>
        </div>
    );
}

export function Loading(props){
    const loading = props.loading;
    if( !loading ){
        return null;
    }
    return(
        <div className="overlay">
          <div className="loading-container lds-dual-ring"></div>
          {/* <div className="loading-container lds-facebook"><div></div><div></div><div></div></div> */}
        </div>
    )
}
